import tw, { styled } from 'twin.macro';
import { Footer } from '../../components/footer';
import Layout from '../../components/Layout';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Navigation } from '../../components/Navigation';

const Container = styled.div`
  ${tw`container w-11/12 md:w-3/4 mx-auto mt-10 py-2 px-3 pr-2 md:py-12 md:px-14 md:pr-12 lg:py-20 lg:px-24 lg:pr-20 h-3/4 bg-gray-100 rounded-xl overflow-scroll overflow-x-hidden`}
`;

function TermsConditions() {
  const { locale } = useIntl();
  return (
    <Layout tw="font-body flex flex-col overflow-x-hidden md:overflow-x-visible">
      <Navigation />
      <h2 tw="flex self-center mt-32 text-black text-center text-3xl md:text-6xl font-bold">
        Terms & Conditions
      </h2>

      <Container className="example">
        <p tw="leading-7 text-sm md:text-base xl:text-lg">
          <h1 tw="font-semibold pb-4 text-xl uppercase">TERMS OF USE</h1>

          <p>
            §1 <strong>Definitions</strong>
          </p>

          <p tw="pb-4 text-sm">
            1. Account - a function of the Platform, thanks to which the User,
            identified by a specific unique name, may use all functions
            available on the Platform.
            <br />
            2. Bidda – Bidda spółka z ograniczoną odpowiedzialnością registered
            in Kraków, address: Olszańska 7, 31-513 Kraków, entered in the
            Register of Entrepreneurs of the National Court Register (KRS) under
            the number 0000905835;
            <br />
            3. Platform - platform available at:
            <a
              href="https://www.bidda.io/"
              target="_blank"
              tw="underline"
              rel="noreferrer"
            >
              {' '}
              https://www.bidda.io/{' '}
            </a>
            <br />
            4. Service, Services - the service described in § 2 par. 2 of the
            Terms of Use;
            <br />
            5. Terms of Use- these terms of use;
            <br />
            6. Agreement - an agreement concluded between the User and Bidda for
            an indefinite period based on the Terms of Use, under which Bidda
            undertakes to provide the Service on the terms specified in the
            Terms of Use;
            <br />
            7. AML Act - the Act of March 1, 2018 on counteracting money
            laundering and terrorist financing (Journal of Laws of 2020, item
            971 as amended);
            <br />
            8. Act on the provision of electronic services - the Act of 18 July
            2002 on the provision of electronic services (Journal of Laws of
            2020, item 344);
            <br />
            9. User - any natural person, legal person, organizational unit
            without legal personality, which the law grants legal capacity with
            an Account.
            <br />
            10. BIDA Token – utility token built on the Polygon network with the
            ERC-20 issued by Bidda;
            <br />
            11. White Paper – BIDDA White Paper available at:{' '}
            <a
              href="https://www.bidda.io/"
              target="_blank"
              tw="underline"
              rel="noreferrer"
            >
              https://www.bidda.io/
            </a>{' '}
          </p>

          <p>
            §2 <strong>General provisions</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. These Terms of Use are also the Regulation on providing
            electronic services within the meaning of Art. 8 of the Act on the
            provision of electronic services. In matters not covered by these
            Terms of Use with regard to the provision of electronic services,
            generally applicable provisions of law shall apply, in particular
            the Act on the provision of electronic services.
            <br />
            2. On the basis of the Terms of Use, Bidda provides to the User, as
            part of the Platform made available to the User, a Service
            consisting of enabling the acquisition of BIDA Tokens.
          </p>

          <p>
            §3 <strong>Use of the Platform</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. To use the Platform and the services provided within it, a device
            with a web browser that supports JavaScript and cookies, with access
            to the Internet, and an active e-mail account are necessary.
            <br />
            2. The User bears the fees related to the access to the Internet in
            accordance with the tariffs of his telecommunications operator.
            <br />
            3. The User is obliged to use the Platform and the Services in
            accordance with the law and the principles of social coexistence. In
            particular, this means that the User is prohibited from providing
            illegal content.
            <br />
            4. Bidda reserves that the use of the Services specified in these
            Terms of Use may be associated with a standard risk related to the
            use of the Internet and recommends Users to take appropriate steps
            to minimize them. Bidda ensures the operation of an ICT system that
            allows the use of the service provided by electronic means in a way
            that prevents unauthorized access to the content of the message
            constituting this service, in particular by using cryptographic
            techniques appropriate to the properties of the service provided, as
            well as unequivocal identification of the parties to the service
            provided by electronic. In the event of planned breaks,
            modernization or other similar cases, Bidda has the right to notify
            Users about them via the e-mail address provided to them or
            generally via the Platform.
            <br />
            5. The User is obliged to protect his passwords, e-mail accounts and
            data needed to log in to the Platform, in particular not to disclose
            them to third parties.
            <br />
            6. Bidda reserves the right to suspend or terminate the provision of
            individual functionalities of the Platform due to the need for
            maintenance, inspection or expansion of the technical base, if it is
            required for the stability of the Platform. Bidda will make every
            effort to notify Users in advance, specifying the date and time of
            the technical break.
          </p>

          <p>
            §4 <strong>Registration on the Platform</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. In order to use all functions of the Platform, the User must
            register on the Platform, i.e.
            <br />
            create an Account.
            <br />
            successfully pass the data identification and verification process
            in accordance with the provisions of the AML Act and internal Bidda
            procedures adopted on the basis of the AML Act. <br />
            read the General Information about the risk and submit a declaration
            of risk acceptance.
            <br />
            read the White Paper and submit a declaration of acceptance.
            <br />
            complete a profile under the Account.
            <br />
            2. Registration is made by providing information about the User in
            the appropriate place on the Platform in accordance with the
            guidelines available on the Platform.
            <br />
            3. By posting data on the Platform, the User declares that they are
            true and consistent with the facts.
            <br />
            4.At the moment of confirmation of the will to register on the
            Platform by the User, an Agreement is concluded between the User and
            Bidda, with the proviso that the User will receive full access to
            all functions of the Platform after the positive verification of his
            data by Bidda, referred to in paragraph 1 and after completing the
            profile data in the Account.
            <br />
            6. The User may have only one Account.
            <br />
            7. In the event that the User registers more than one Account or
            undertakes actions aimed at this, Bidda shall have the right to
            terminate the Agreement with immediate effect - in relation to each
            of the Accounts set up by the User.
          </p>

          <p>
            §5 <strong>Responsibility</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Bidda shall be liable to Users for non-performance or improper
            performance of the Service to the extent resulting from the Terms of
            Use, unless the non-performance or improper performance of the
            Service is a consequence of circumstances for which it is not
            responsible by law or by the Terms of Use.
            <br />
            2. Bidda shall not be liable for the consequences of non-performance
            or improper performance of obligations assumed by other Users
            towards the User.
            <br />
            3. Bidda is not responsible for:
            <br />
            deletion of data entered by Users into the Platform's ICT system
            through ICT systems beyond the control of Bidda;
            <br />
            the consequences of providing third parties with access to the login
            and password to the Account by third parties,
            <br />
            incorrect entry by the User of data for the payment or withdrawal of
            funds;
            <br />
            financial losses incurred by Users resulting from the inability to
            make transactions via the Platform during the technical break,
            <br />
            Users' financial losses resulting from exchange rate differences
            arising during the blocking of a given transaction or User Account
            made in accordance with the Terms of Use or at the request of public
            authorities,
            <br />
            technical problems or limitations, including the speed of data
            transmission of computer equipment, terminal equipment, ICT system
            and telecommunications infrastructure used by the User, which
            prevent the User from using the Platform,
            <br />
            the effects of using the Platform by the User in a manner
            inconsistent with applicable law, the Terms of Use or the principles
            of social coexistence or customs adopted in this regard.
            <br />
            4. The above provision does not prejudice the Users' rights granted
            under generally applicable provisions.
          </p>

          <p>
            §6 <strong>Complaints</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Complaints regarding the functioning of the Platform or services
            provided through it may be submitted by the User electronically to
            the following e-mail address: hello@bidda.io <br />
            2. In the content of the electronic message referred to in para. 1
            should be included:
            <br />
            a) name and surname or company of the person submitting the
            complaint;
            <br />
            b) e-mail address used to log into the Account (if applicable).
            <br />
            3. The complaint should additionally include a detailed description
            of the event constituting the basis for the notification and
            indication of the claims of the claimant
            <br />
            4. Complaints may be submitted within 60 (sixty) days from the date
            of the event giving rise to the complaint. About keeping the
            deadline for the complaint submitted to the e-mail address indicated
            in paragraph 1, the date of dispatch of the complaint via the e-mail
            address of the claimant is decisive.
            <br />
            5. Bidda reserves the right not to consider the complaint if the
            claimant has not met the conditions referred to in sec. 1-3 above
            and did not meet the deadline referred to in sec. 4 above.
            <br />
            6. Bidda shall consider the complaint immediately, not later than
            within 30 days of its receipt. After the complaint procedure is
            exhausted, the person submitting the complaint has the right to
            pursue unrecognized claims in the common court competent for the
            seat of Bidda.
          </p>

          <p>
            §7 <strong>Personal data</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. The Platform does not process any data of its Users who do not
            have a User Account.
            <br />
            2. Bidda is the administrator of the personal data of Users who have
            a User Account and entities that submitted a complaint.
            <br />
            3. Personal data of Users who have a User Account are processed by
            the Administrator pursuant to art. 6 sec. 1 lit. b GDPR solely for
            the purpose of maintaining a User Account and ensuring the
            possibility of using the Platform functionalities provided for a
            User who has a User Account.
            <br />
            4. Personal data of entities that submitted a complaint are
            processed by the Administrator pursuant to art. 6 sec. 1 lit. c GDPR
            only to consider the complaint and are processed as long as it is
            necessary to ensure the highest quality of service on the Platform.
            <br />
            5. The administrator may process the personal data of the entities
            referred to in sec. 3-4 above, pursuant to Art. 6 sec. 1 lit. f
            GDPR, if it is necessary for the purposes of the legitimate
            interests pursued by the Administrator.
            <br />
            6. The administrator may share the personal data of the entities
            referred to in sec. 3-4 above, in accordance with applicable law, at
            the request of law enforcement authorities, other public
            administration bodies, the Police and other services. The provision
            of personal data does not require the consent of the Website User.
            <br />
            7. The entities referred to in sec. 3-4 above, in connection with
            the processing of their personal data, you have the right to access
            the data, the right to rectify the data, and the right to request
            the transfer of data, the right to lodge a complaint with the
            supervisory authority - the President of the Office for Personal
            Data Protection.
            <br />
            8. The entities referred to in sec. 3-4 above, you have the right to
            delete data, the right to limit data processing, the right to object
            to data processing, unless otherwise opposed by generally applicable
            law or the nature of the legal relationship between the indicated
            entity and the Administrator.
            <br />
            9. Personal data is not transferred to third countries outside the
            European Economic Area. Personal data may be entrusted to third
            parties in order to use the services of an e-mail provider, provide
            legal services and use the IT website.
          </p>

          <p>
            §8 <strong>Final Provisions</strong>
          </p>
          <p tw="text-sm">
            1. The law applicable to matters related to the Terms of Use
            (including the services provided) is Polish law.
            <br />
            2. Any disputes between Bidda and the User will be settled by the
            court competent for the seat of Bidda.
            <br />
            3. The current version of the Terms of Use was adopted on 9 July
            2021.
          </p>
        </p>
      </Container>
      <Container className="example" tw="mt-20">
        <p tw="leading-7 text-sm md:text-base xl:text-lg">
          <h1 tw="font-semibold pb-4 text-xl uppercase">
            REGULAMIN KORZYSTANIA Z PLATFORMY INTERNETOWEJ
          </h1>
          <p tw="">
            <a
              href="https://www.app.bidda.io/"
              target="_blank"
              rel="noreferrer"
              tw="text-[darkBlue]"
            >
              www.app.bidda.io
            </a>
          </p>
          <p tw="mt-6">
            <strong>§1. Postanowienia ogólne</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Przedmiotem Regulaminu jest określenie warunków i zasad
            korzystania z platformy internetowej www.bidda.io, trybu
            postępowania reklamacyjnego, zasad przetwarzania danych osobowych, a
            także praw, obowiązków i zakresu odpowiedzialności Bidda oraz
            Użytkownika.
            <br />
            2. Właścicielem oraz administratorem platformy internetowej
            www.bidda.io jest spółka pod firmą Bidda sp. z o.o. z siedzibą w
            Krakowie, adres: Olszańska 7, 31-513 Kraków wpisana do rejestru
            przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa
            Śródmieścia w Krakowie XI Wydział Gospodarczy Krajowego Rejestru
            Sądowego pod numerem KRS: 0000905835, posiadającą NIP: 6751752502,
            oraz REGON: 3891784810000
            <br />
            3. Warunkiem korzystania z platformy internetowej www.bidda.io jest
            zapoznanie się z treścią oraz akceptacja Regulaminu. Użytkownik
            zaznaczając opcję „Akceptuję regulamin” oświadcza, że zapoznał się z
            warunkami Regulaminu oraz, że są one dla niego zrozumiałe i
            zobowiązuje się do przestrzegania jego postanowień.
            <br />
            4. Regulamin nie określa w żaden sposób wzajemnych praw i obowiązków
            Klienta i i Usługodawcy. Stosunek prawny pomiędzy Klientem oraz
            Usługodawcą określa wyłącznie umowa lub inna czynność prawna
            dokonana między tymi podmiotami.
            <br />
            5. Korzystanie z Platformy jest dobrowolne.
            <br />
            6. Korzystanie z Platformy jest nieodpłatne lub częściowo odpłatne.
          </p>
          <p tw="mt-6">
            <strong>§1. Definicje</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. <strong>Bidda sp. z o.o., Bidda</strong> - rozumie się przez to
            Bidda spółkę z ograniczoną odpowiedzialnością z siedzibą w Krakowie,
            adres: Olszańska 7, 31-513 Kraków wpisaną do rejestru
            przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa
            Śródmieścia w Krakowie XI Wydział Gospodarczy Krajowego Rejestru
            Sądowego pod numerem KRS: 0000905835, posiadającą NIP: 6751752502,
            oraz REGON: 3891784810000;
            <br />
            2. <strong>Platformie</strong> - rozumie się przez to platformę
            bidda.io umożliwiającą Klientom rezerwowanie usług Usługodawców;
            <br />
            3. <strong>Regulaminie</strong> - rozumie się przez to niniejszy
            Regulamin;
            <br />
            4. <strong>Użytkowniku</strong> - rozumie się przez to każdą osobę
            fizyczną, osobę prawną lub jednostkę organizacyjną nieposiadająca
            osobowości prawnej, mającą zdolność prawną, która korzysta w
            jakikolwiek sposób z Platformy.
            <br />
            5. <strong>Usługodawcy</strong> - rozumie się przez to Użytkownika,
            którego usługi można zarezerwować za pośrednictwem Serwisu Bidda;
            <br />
            6. <strong>Usłudze</strong> - rozumie się przez to usługę świadczoną
            przez Usługodawcę, której rezerwacji można dokonać za pośrednictwem
            Platformy lub która może być przedmiotem aukcji na Platformie;
            <br />
            7. <strong>Kliencie</strong> - rozumie się przez to Użytkownika,
            który za pośrednictwem Platformy rezerwuję Usługę lub uczestnicy w
            aukcji Usługi.
            <br />
            8. <strong>Tabela opłat</strong> - dokument zawierający informacje o
            opłatach pobieranych za korzystanie z Platformy, zamieszczony na
            stronie [...]
          </p>
          <p tw="mt-6">
            <strong>§3. Zasady działania Platformy</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Klientem może być każda osoba fizyczna. Usługodawcą może być
            wyłącznie osoba fizyczna, osoba prawna lub jednostka niemająca
            osobowości prawnej, ale posiadająca zdolność prawną.
            <br />
            2. W ramach Platformy dla Użytkowników dostępne są następujące
            funkcjonalności:
            <br />
            <p tw="ml-2">
              1) Możliwość uzyskania informacji na temat przedmiotu działalności
              oraz Usług świadczonych przez Usługodawcę;
            </p>
            <p tw="ml-2">2) Możliwość rezerwacji Usługi;</p>
            <p tw="ml-2">3) Możliwość wystawienia Usługi na aukcję.</p>
            3. Zabrania się dostarczania przez Użytkowników treści o charakterze
            bezprawnym, jak również wykorzystywania Platformy do celów
            niezgodnych z prawem.
            <br />
            4. Bidda jedynie udostępnia Platformę w celu umożliwienia nawiązania
            kontaktu pomiędzy Użytkownikami oraz zawarcia umowy pomiędzy
            Użytkownikami. Bidda nie jest stroną tych umów, nie ponosi żadnej
            odpowiedzialności względem Użytkowników za należyte wykonanie Usług
            lub wykonanie tych umów, jak również nie ponosi odpowiedzialności za
            dokonanie płatności za Usługę.
            <br />
            5. Korzystanie przez Usługodawców z Platformy jest odpłatne. Opłata
            od Usługodawcy jest pobierana wyłącznie w przypadku dokonania przez
            Klienta rezerwacji Usługi Usługodawcy i jest uzależniona od ceny
            Usługi przez Szczegółowe zasady pobierania opłat określają Tabela
            Opłat.
            <br />
            6. Bidda nie pobiera od Klientów żadnych opłat za możliwość
            rezerwowania Usług.
            <br />
            7. Bidda ponosi opłaty od Klientów i Usługodawców za wystawienie
            Usługi na aukcję. Szczegółowe zasady pobierania opłat określają
            Tabela Opłat.
            <br />
          </p>
          <p tw="mt-6">
            <strong>§4. Świadczenie usług drogą elektroniczną</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Bidda jest jednocześnie usługodawcą usług świadczonych drogą
            elektroniczną w rozumieniu ustawy z dnia 18 lipca 2002 r. o
            świadczeniu usług drogą elektroniczną (t.j. Dz.U. z 2019 r. poz. 123
            z późn. zm.), zaś niniejszy Regulamin stanowi jednocześnie regulamin
            świadczenia usług drogą elektroniczną w rozumieniu art. 8 tej
            ustawy.
            <br />
            2. Bidda dołoży starań, aby korzystanie z Platformy było możliwe dla
            korzystających z sieci Internet przy użyciu wszystkich popularnych
            przeglądarek internetowych, systemów operacyjnych, typów urządzeń
            oraz typów połączeń internetowych. Minimalne wymagania techniczne
            umożliwiające korzystanie z Serwisu to przeglądarka internetowa w
            wersji co najmniej Internet Explorer 7 lub Chrome 66 lub FireFox 60
            lub Opera 53 lub Safari 5 lub nowszych, z włączoną obsługą języka
            Javascript, akceptująca pliki typu „cookies” oraz łącze internetowe
            o przepustowości co najmniej 256 kbit/s. Platforma jest
            zoptymalizowana dla minimalnej rozdzielczości ekranu 1024x768
            pikseli.
            <br />
            3. Bidda zastrzega, że korzystanie z usług określonych w niniejszym
            Regulaminie może wiązać się ze standardowym ryzykiem związanym z
            wykorzystaniem sieci Internet i rekomenduje Użytkownikom
            przedsięwzięcie odpowiednich kroków w celu ich zminimalizowania.
            Bidda zapewnia działanie systemu teleinformatycznego, który
            umożliwia korzystanie z usługi świadczonej drogą elektroniczną, w
            sposób uniemożliwiający dostęp osób nieuprawnionych do treści
            przekazu składającego się na tę usługę, w szczególności przy
            wykorzystaniu technik kryptograficznych odpowiednich dla właściwości
            świadczonej usługi, a także jednoznaczną identyfikację stron usługi
            świadczonej drogą elektroniczną. W razie planowanych przerw,
            modernizacji lub też w innych podobnych wypadkach, Bidda ma prawo
            powiadomić o nich Użytkowników poprzez udostępniony mu adres email
            lub ogólnie na Platformie.
            <br />
            4. W sprawach nieuregulowanych w niniejszym Regulaminie w zakresie
            świadczenia usług drogą elektroniczną zastosowanie mają powszechnie
            obowiązujące przepisy prawa, w tym w szczególności o świadczeniu
            usług drogą elektroniczną.
          </p>
          <p tw="mt-6">
            <strong>§5. Prawa własności intelektualnej</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Bidda przysługują wszelkie prawa własności intelektualnej, w tym
            prawa autorskie, do treści zamieszczonych na Platformie, za
            wyjątkiem informacji oraz plików zamieszczonych na podstronach
            Usługodawców, do których wszelkie prawa własności intelektualnej
            przysługują Usługodawcy.
            <br />
            2. Zawartość Platformy nie może być zwielokrotniana,
            rozpowszechniana ani publikowana w innych celach niż na dozwolony
            użytek prywatny.
            <br />
            3. Użytkownik, ma prawo przeglądać Platformę na swoim komputerze lub
            drukować fragmenty tych stron wyłącznie dla użytku osobistego, a nie
            w celu rozpowszechniania lub redystrybucji, chyba że Bidda wyrazi na
            to pisemną zgodę.
            <br />
            4. Poszczególne dokumenty zamieszczone na Platformie mogą podlegać
            dodatkowym warunkom określonym w tych dokumentach.
            <br />
            5. Wszelkie działania naruszające prawa autorskie lub prawa
            własności przemysłowej Bidda są zabronione.
            <br />
          </p>
          <p tw="mt-6">
            <strong>§6. Polityka Cookies</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Platforma nie zbiera w sposób automatyczny żadnych informacji, z
            wyjątkiem informacji zawartych w plikach cookies.
            <br />
            2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Użytkownika i przeznaczone są do korzystania z Platformy.
            Cookies zazwyczaj zawierają nazwę strony internetowej, z której
            pochodzą, czas przechowywania ich na urządzeniu końcowym oraz
            unikalny numer.
            <br />
            3. Pliki cookies wykorzystywane są w celu:
            <br />
            <p tw="ml-2">
              a. dostosowania zawartości stron internetowych Platformy do
              preferencji Użytkownika, oraz optymalizacji korzystania ze stron
              internetowych; w szczególności pliki te pozwalają rozpoznać
              urządzenie Użytkownika i odpowiednio wyświetlić stronę
              internetową, dostosowaną do jego indywidualnych potrzeb;
            </p>
            <p tw="ml-2">
              b. tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
              Użytkownicy korzystają ze stron internetowych, co umożliwia
              ulepszanie ich struktury i zawartości;
            </p>
            <p tw="ml-2">
              c. utrzymanie sesji Użytkownika na Koncie Użytkownika;
            </p>
            <p tw="ml-2">
              d. współpracy z osobami odpowiedzialnymi za marketing usługi.
            </p>
            4. W ramach Portalu stosowane są dwa zasadnicze rodzaje plików
            cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
            cookies). Cookies „sesyjne” są plikami tymczasowymi, które
            przechowywane są w urządzeniu końcowym Użytkownika, do czasu
            opuszczenia strony internetowej lub wyłączenia oprogramowania
            (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są
            w urządzeniu końcowym Użytkownika przez czas określony w parametrach
            plików cookies lub do czasu ich usunięcia przez Użytkownika.
            <br />
            5. Na Platformie stosowane są następujące rodzaje plików cookies:
            <br />
            <p tw="ml-2">
              a. „niezbędne” pliki cookies, umożliwiające korzystanie z usług
              dostępnych na Platformie, np. uwierzytelniające pliki cookies
              wykorzystywane do usług wymagających uwierzytelniania na
              Platformie;
            </p>
            <p tw="ml-2">
              b. pliki cookies służące do zapewnienia bezpieczeństwa, np.
              wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania
              na Platformie;
            </p>
            <p tw="ml-2">
              c. „wydajnościowe” pliki cookies, umożliwiające zbieranie
              informacji o sposobie korzystania ze stron internetowych
              Platformy;
            </p>
            <p tw="ml-2">
              d. „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie”
              wybranych przez Użytkownika, ustawień i personalizację interfejsu
              Użytkownika np. w zakresie wybranego języka lub regionu, z którego
              pochodzi Użytkownik rozmiaru czcionki, wyglądu strony internetowej
              itp.;
            </p>
            <p tw="ml-2">
              e. „reklamowe” pliki cookies, umożliwiające dostarczanie
              Użytkownikowi treści reklamowych bardziej dostosowanych do jego
              zainteresowań.
            </p>
            6. W wielu przypadkach oprogramowanie służące do przeglądania stron
            internetowych (przeglądarka internetowa) domyślnie dopuszcza
            przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
            Użytkownik może dokonać w każdym czasie zmiany ustawień dotyczących
            plików cookies. Ustawienia te mogą zostać zmienione w szczególności
            w taki sposób, aby blokować automatyczną obsługę plików cookies w
            ustawieniach przeglądarki internetowej bądź informować o ich
            każdorazowym zamieszczeniu w urządzeniu Użytkownika. Szczegółowe
            informacje o możliwości i sposobach obsługi plików cookies dostępne
            są w ustawieniach oprogramowania (przeglądarki internetowej).
            <br />
            7. Bidda Platformy informuje, że ograniczenia stosowania plików
            cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
            stronach internetowych Platformy.
            <br />
            8. Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
            wykorzystywane mogą być również przez współpracujących z Biddaem
            reklamodawców oraz partnerów.
            <br />
          </p>
          <p tw="mt-6">
            <strong>§7. Reklamacje</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Użytkownikom przysługuje prawo składania reklamacji dotyczących
            usług dostępnych przy użyciu Platformy.
            <br />
            2. Reklamacje oraz prośby o rozwiązanie umowy o świadczenie usług
            drogą elektroniczna mogą być zgłaszane pocztą elektroniczną na adres
            email{' '}
            <a href="mailto:hello@bidda.io" tw="text-[darkBlue]">
              hello@bidda.io
            </a>
            3. W treści wiadomości elektronicznej, o której mowa w ust. 2 należy
            zamieścić:
            <br />
            <p tw="ml-2">
              1) imię i nazwisko lub firmę zgłaszającego reklamację;
            </p>
            <p tw="ml-2">
              2) adres zamieszkania zgłaszającego reklamację i adres email;
            </p>
            4. Reklamacja powinna obejmować dodatkowo dokładny opis zdarzenia
            stanowiącego podstawę zgłoszenia oraz wskazanie żądań zgłaszającego
            reklamację.
            <br />
            5. Reklamacje mogą być zgłaszane w terminie 60 (sześćdziesięciu) dni
            od daty zaistnienia zdarzenia będącego podstawą zgłoszenia
            reklamacji. O zachowaniu terminu reklamacji zgłoszonej na adres
            email{' '}
            <a href="mailto:hello@bidda.io" tw="text-[darkBlue]">
              hello@bidda.io
            </a>{' '}
            decyduje data wysłania reklamacji za pośrednictwem poczty email
            zgłaszającego reklamację. 6. Bidda nie rozpatrzy reklamacji, jeżeli
            zgłaszający nie spełnił warunków, o których mowa w ust. 3 i ust. 4
            powyżej oraz nie zachował terminu, o którym mowa w ust. 5 powyżej.
            <br />
            7. Bidda rozpatruje reklamacje w terminie 30 (trzydziestu) dni
            kalendarzowych od dnia doręczenia reklamacji. Po wyczerpaniu
            postepowania reklamacyjnego zgłaszającemu reklamację przysługuje
            prawo do dochodzenia nieuwzględnionych roszczeń w sądzie powszechnym
            właściwym miejscowo dla siedziby Bidda.
          </p>
          <p tw="mt-6">
            <strong>§8. Ochrona Danych Osobowych</strong>
          </p>
          <p tw="pb-4 text-sm">
            1. Na Platformie nie są przetwarzane żadne dane jej Użytkowników,
            którzy nie posiadają Konta Użytkownika.
            <br />
            2. Administratorem danych osobowych Użytkowników, oraz podmiotów,
            które zgłosiły reklamację jest Bidda sp. z o.o.
            <br />
            3. Dane osobowe Użytkowników są przetwarzane przez Administratora na
            podstawie art. 6 ust. 1 lit. b RODO wyłącznie w celu zapewnienia
            możliwości korzystania z funkcjonalności Platformy.
            <br />
            4. Dane osobowe podmiotów, które zgłosiły reklamację, przetwarzane
            są przez Administratora na podstawie art. 6 ust. 1 lit. c RODO
            wyłącznie w celu rozpatrzenia reklamacji i są przetwarzane tak
            długo, jak jest to niezbędne dla zapewnienia najwyższej jakości
            obsługi na Platformie.
            <br />
            5. Administrator może przetwarzać dane osobowe podmiotów, o których
            mowa w ust. 3-4 powyżej, na podstawie art. 6 ust. 1 lit. f RODO,
            jeżeli jest niezbędne do celów wynikających z prawnie uzasadnionych
            interesów realizowanych przez Administratora.
            <br />
            6. Administrator może udostępniać dane osobowe podmiotów, o których
            mowa w ust. 3-4 powyżej, zgodnie z obowiązującymi przepisami prawa
            na żądanie organów nadzorujących przestrzeganie prawa, innych
            organów administracji publicznej, Policji oraz innych służb.
            Udostępnienie danych osobowych nie wymaga zgody Użytkownika Serwisu.
            <br />
            7. Podmiotom, o których mowa w ust. 3-4 powyżej, w związku z
            przetwarzaniem ich Danych Osobowych przysługuje prawo dostępu do
            treści danych, prawo do sprostowania danych, oraz prawo do żądania
            przeniesienia danych, prawo do wniesienia skargi do organu nadzoru –
            Prezesa Urzędu Ochrony Danych Osobowych.
            <br />
            8. Podmiotom, o których mowa w ust. 3-4 powyżej, przysługuje prawo
            do usunięcia danych, prawo do ograniczenia przetwarzania danych,
            prawo do wniesienia sprzeciwu wobec przetwarzania danych, o ile nie
            sprzeciwiają się temu przepisy prawa powszechnie obowiązującego lub
            natura stosunku prawnego łączącego wskazany podmiot i
            Administratora.
            <br />
            9. Dane osobowe nie są przekazywane do Państw Trzecich spoza
            Europejskiego Obszaru Gospodarczego. Dane osobowe mogą zostać
            powierzone podmiotom trzecim w celu korzystania z usług dostawcy
            poczty elektronicznej, zapewnienia obsługi prawnej oraz korzystania
            z serwisu IT.
          </p>
          <p tw="mt-6">
            <strong>§9. Postanowienia końcowe</strong>
          </p>
          <p tw="pb-4 text-sm">
            Bidda zastrzega sobie prawo do wprowadzania zmian w niniejszym
            Regulaminie w celu zapewnienia jego aktualności i zgodności z
            obowiązującymi przepisami prawa. Zmiana Regulaminu.
          </p>
        </p>
      </Container>
      <div tw="bg-dark mt-20 px-5">
        <Footer />
      </div>
    </Layout>
  );
}

export default TermsConditions;
